import { Route, Routes } from 'react-router-dom';
import './styles/styles.scss'
import './App.scss';
import { Layout } from './components/Layout';
import Home from './components/Home'
import About from './components/About';
import Contact from './components/Contact';
import { Portfolio } from './components/Portfolio';
import { StudioProvider } from './components/StudioContext';

function App() {
  return (
    <>
    <StudioProvider>
      <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/contact" element={<Contact />} />
            </Route>
        </Routes>
    </StudioProvider>
    </>
  )
}

export default App 