import { useEffect, useState, useContext} from 'react';
import lang1 from '../assets/images/HTML.svg'
import lang2 from '../assets/images/CSS.svg'
import lang3 from '../assets/images/JavaScript.svg'
import lang4 from '../assets/images/React-Dark.svg'
import lang5 from '../assets/images/Sass.svg'
import lang6 from '../assets/images/NodeJS-Dark.svg'
import lang7 from '../assets/images/ExpressJS-Dark.svg'
import lang8 from '../assets/images/MongoDB.svg'
import lang9 from '../assets/images/MySQL-Dark.svg'
import tool1 from '../assets/images/Docker.svg'
import tool2 from '../assets/images/Git.svg'
import tool3 from '../assets/images/stripe.png'
import tool4 from '../assets/images/gears-api.svg'
import tool4b from '../assets/images/white-gear-api.svg'
import tool5 from '../assets/images/microsoft-office.svg'
import shonen from '../assets/images/shonen.webp'
import AnimatedLetters from './AnimatedLetters';
import ScrollAnimation from './Scroll';
import { StudioContext } from './StudioContext';

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const {activeBackground} = useContext(StudioContext);

  const languages = [{name: 'HTML5', stlyeName: 'html', image: lang1}, {name: 'CSS3', stlyeName: 'css', image: lang2}, 
    {name: 'JavaScript', stlyeName: 'javascript', image: lang3}, {name: 'React', stlyeName: 'reactjs', image: lang4},
    {name: 'Sass', stlyeName: 'sassy', image: lang5}, {name: 'Node.js', stlyeName: 'node', image: lang6},
    {name: 'Express.js', stlyeName: 'express', image: lang7}, {name: 'MongoDB', stlyeName: 'mongo', image: lang8}, 
    {name: 'MySQL', stlyeName: 'mysql', image: lang9}]

  const dev_tools = [ {name: 'Docker', styleName: 'docker', image: tool1, desc: 'Application Containerization & Virtualization'}, 
    {name: 'Stripe', stlyeName: 'stripe', image: tool3, desc: 'Payment Solutions'}, 
    {name: 'APIs', stlyeName: 'apis', image: tool4, desc: 'Application Programming Interfaces'},
    {name: 'MS Office', stlyeName: 'msoffice', image: tool5, desc: 'Business Productivity Tools'},
    {name: 'Git', stlyeName: 'git', image: tool2, desc: 'Version Control System'},
   ]

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
  
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
     <div className={`about-page hidden ${activeBackground ? 'dark-mode' : ''}`}>
      <h1>
        <AnimatedLetters
          letterClass={letterClass}
          strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
          idx={15}
        />
      </h1>
      <div className='contents hidden'>
        <p className='intro'>Hi, my <br/> name is <b>Paul</b><span>.</span></p>
        <p className='first-sentence'>
          I'm an <b> economics undergraduate </b> student at UConn and a <b>full-stack developer</b> from NY.  
        </p>
        <div class="dot-header__dots hidden">
          <div class="dots"></div>
        </div>
       <ScrollAnimation/>
        <div className='mid'>
          <p className='anime'>
            <b className='know-me'>Get to know me.</b><br/>
            As an avid fan of anime and video games, I value complex storytelling and intricate 
            designs—elements that often inspire my work. My technical ability and problem-solving 
            mindset allow me to bridge the gap between creative vision and functional execution, 
            transforming abstract ideas into tangible applications.
          </p>
          <img className='shonen' src={shonen} alt='shonen-one-piece'/>
        </div>
        <div className='tools-used'>
          <p className='toolkit'>My Toolkit.</p>
          <div className='bottom'>
            <div className='languages'>
              {languages.map((item, index) => (
                <div key={index} className={`lang-container ${item.name}`}>
                  <img src={item.image} className='lang-image' alt={item.name}/>
                  <p className='lang-name'>{item.name}</p>
                </div>
              ))}
            </div>
            <p className='skills'>
              If you're seeking someone experienced in creating Single Page Applications <b>(SPAs) </b> 
              using <b>React JS</b>, or in developing and utilizing <b> APIs—RESTful</b> or 
              otherwise—through <b>Node.js</b> and <b>Express</b>, then I'm well-equipped to assist you.
            </p>
          </div>
        </div>
        <div className='application-integrations'>
          <h1 className='integration-header'>Integration Capabilities.</h1>
          <div className='integration-grid'>
            {dev_tools.map((item, index) => (
              <div key={index} className={`tool-container ${item.name}`}>
                <img 
                  src={item.name === 'APIs' && activeBackground ? tool4b : item.image} 
                  className={`tool-image ${item.styleName}`} 
                  alt={item.name}
                />
                <div className='tool-info'>
                  <p className='tool-name'>{item.name}</p>
                  <p className='tool-desc'>{item.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <p className='build'>Let's build something meaningful.</p>
      </div>
    </div>
    </>
  )
}

export default About