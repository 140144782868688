import { useEffect, useState, useContext } from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from './AnimatedLetters'
import 'leaflet/dist/leaflet.css';
import Leaf from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { StudioContext } from './StudioContext.jsx';

delete Leaf.Icon.Default.prototype._getIconUrl;

Leaf.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});


const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const {activeBackground} = useContext(StudioContext);
    const form = useRef()

    useEffect(() => {
        const timer = setTimeout(() => {
          setLetterClass('text-animate-hover');
        }, 4000);
      
        // Cleanup function
        return () => clearTimeout(timer);
      }, []);
    
    
  const sendEmail = (e) => {
    e.preventDefault()

    var params = {
        from_name: document.getElementById("name").value,
        email_id: document.getElementById("email").value,
        message: document.getElementById("message").value
    }
    emailjs
      .send('service_xl3f98h', 'template_2apaski', params, 'dXhacki60e9sZ829w')
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
    }  

    return (
        <div className={`container contact-page ${activeBackground ? 'dark-mode' : ''}`}>
            <div className="text-zone">
                <h1>
                    <AnimatedLetters letterClass={letterClass}
                        strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
                        idx={15}/>
                </h1>
                <div className="contact-form">
                    <form ref={form} onSubmit={sendEmail}>
                        <ul>
                            <li className="half">
                                <input id="name" placeholder="Name" type="text" name="name" required />
                            </li>
                            <li className="half">
                                <input id="email" placeholder="Email" type="email" name="email" required/>
                            </li>
                            <li>
                                <input placeholder="Subject" type="text" name="subject" required/>
                            </li>
                            <li>
                                <textarea id="message" placeholder="Message" name="message" required></textarea>
                            </li>
                            <li>
                                <input type="submit" className="flat-button" value="SEND" />
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
            <div className="map-wrap">
                <MapContainer className='mapContainer' center={[41.81, -72.25]} zoom={13}>
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    <Marker position={[41.81, -72.25]} popupOpen>
                        <Popup>
                            Paul Mooh Mooh<br />
                            CT, USA<br />
                            <a href="mailto:paulmooh@gmail.com">paulmooh@gmail.com</a>
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
        </div>
    )
}

export default Contact;