import React, {useContext, useState} from 'react'
import { NavLink, Outlet } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaMoon } from "react-icons/fa";
import { StudioContext } from './StudioContext';
import { IoSunnyOutline } from "react-icons/io5";

export const Layout = () => {

  const [isVisible, setVisible] = useState(false);

  const toggleSidebar = () => {
    setVisible(!isVisible);
  };
  const {changeBackground, activeBackground} = useContext(StudioContext);

 return (
    <div className='layout'>
      <div className={`header ${activeBackground ? 'dark-mode' : ''}`}>
      <NavLink className={({ isActive }) => isActive ? 'changer active' : 'changer'} to="/">Home</NavLink>
      <NavLink className={({ isActive }) => isActive ? 'changer active' : 'changer'} to="/about">About</NavLink>
      <NavLink className={({ isActive }) => isActive ? 'changer active' : 'changer'} to="/portfolio">Portfolio</NavLink>
      <NavLink className={({ isActive }) => isActive ? 'changer active' : 'changer'} to="/contact">Contact</NavLink>
      </div>
      <GiHamburgerMenu onClick={toggleSidebar} color={activeBackground ? 'white' : 'black'} className="hamburger-icon"/>
      <div className="change-background" >
        <button className={`change-button ${activeBackground ? 'dark-mode' : ''}`} onClick={changeBackground}>
          {activeBackground ? <IoSunnyOutline /> : <FaMoon/> }
        </button>
      </div>
      <div className={`page ${activeBackground ? 'dark-mode' : ''}`}>
        <Outlet />
      </div>
      {isVisible && <Sidebar className={`${isVisible ? 'visible' : ''}`}/>}
    </div>
 )
}

