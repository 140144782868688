import React, { useContext } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome, faUser, faEnvelope, faSuitcase} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { StudioContext } from './StudioContext';

export const Sidebar = ({className}) => {
  const {activeBackground} = useContext(StudioContext);


  return (
    <div className={`nav-bar ${className}`}>
      <nav>
           <NavLink exact="true" activeClassname="active" to="/" >
             <FontAwesomeIcon icon={faHome} color={activeBackground ? 'white' : 'black'} />
           </NavLink>
           <NavLink exact="true" activeClassname="active" className='about-link' to="/about" >
             <FontAwesomeIcon icon={faUser} color={activeBackground ? 'white' : 'black'} />
           </NavLink>
           <NavLink exact="true" activeClassname="active" className='portfolio-link' to="/portfolio" >
             <FontAwesomeIcon icon={faSuitcase} color={activeBackground ? 'white' : 'black'} />
           </NavLink>
           <NavLink exact="true" activeClassname="active" className='contact-link' to="/contact" >
             <FontAwesomeIcon icon={faEnvelope} color={activeBackground ? 'white' : 'black'} />
           </NavLink>
         </nav>
    </div>
  )
}
