"use client";
import React, { useEffect, useRef, useCallback, useContext } from "react";
import { createNoise3D } from "simplex-noise";
import { cn } from "./cn.ts";
import { StudioContext } from "../components/StudioContext.jsx";

export const WavyBackground = ({
  children,
  className,
  containerClassName,
  colors = [
    "#38bdf8",
    "#818cf8",
    "#c084fc",
    "#e879f9",
    "#22d3ee",
  ],
  waveWidth = 50,
  backgroundFill: defaultBackgroundFill = "white",
  blur = 10,
  speed = "fast",
  waveOpacity = 0.5,
  ...props
}: {
  children?: any;
  className?: string;
  containerClassName?: string;
  colors?: string[];
  waveWidth?: number;
  backgroundFill?: string;
  blur?: number;
  speed?: "slow" | "fast";
  waveOpacity?: number;
  [key: string]: any;
}) => {
  const {activeBackground} = useContext(StudioContext);
  const backgroundFill = activeBackground ? "black" : defaultBackgroundFill;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationIdRef = useRef<number>(-1);
  const noise = createNoise3D();

  const getSpeed = useCallback(() => {
    switch (speed) {
      case "slow":
        return 0.001;
      case "fast":
        return 0.002;
      default:
        return 0.001;
    }
  }, [speed]);

  const drawWave = useCallback((ctx: CanvasRenderingContext2D, w: number, h: number, nt: number) => {
    ctx.fillStyle = backgroundFill;
    ctx.globalAlpha = waveOpacity;
    ctx.fillRect(0, 0, w, h);

    for (let i = 0; i < 5; i++) {
      ctx.beginPath();
      ctx.lineWidth = waveWidth;
      ctx.strokeStyle = colors[i % colors.length];
      for (let x = 0; x < w; x += 5) {
        const y = noise(x / 800, 0.3 * i, nt) * 100 + h * 0.5;
        ctx.lineTo(x, y);
      }
      ctx.stroke();
      ctx.closePath();
    }
  }, [colors, waveWidth, backgroundFill, waveOpacity, noise]);

  const render = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const w = canvas.width = window.innerWidth;
    const h = canvas.height = window.innerHeight;
    ctx.filter = `blur(${blur}px)`;

    let nt = 0;
    const draw = () => {
      drawWave(ctx, w, h, nt);
      nt += getSpeed();
      animationIdRef.current = requestAnimationFrame(draw);
    };
    draw();
  }, [drawWave, getSpeed, blur]);

  useEffect(() => {
    render();
    return () => {
      cancelAnimationFrame(animationIdRef.current);
    };
  }, [render]);

  return (
    <div className={cn("h-screen flex flex-col items-center justify-center", containerClassName)}>
      <canvas className="absolute inset-0 z-0" ref={canvasRef} id="canvas"></canvas>
      <div className={cn("relative z-10", className)} {...props}>
        {children}
      </div>
    </div>
  );
};
