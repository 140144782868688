import { useEffect, useState, useContext} from 'react';
import AnimatedLetters from './AnimatedLetters.jsx';
import { faDownload} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { WavyBackground } from '../utils/background.tsx';
import { StudioContext } from './StudioContext.jsx';

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const {activeBackground} = useContext(StudioContext);

 
  const Studio = ['M','o','o','h',' ','S','t','u','d','i','o',]

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
  
    return () => clearTimeout(timer);
  }, []);
  
 return (
  <>
  <WavyBackground>
  <div className="home-page">
    <div className="text-zone">
      <h1 className={`studio ${activeBackground ? 'dark-mode' : ''}`}>
        <AnimatedLetters letterClass={letterClass} strArray={Studio} idx={11}/>
      </h1>
      <div className='sub-details'>
      <Link to="/contact" className={`contact-link ${activeBackground ? 'dark-mode' : ''}`}>Contact Me</Link>
      <div className={`btn-con ${activeBackground ? 'dark-mode' : ''}`}>
          <a href={`${process.env.PUBLIC_URL}/Resume.pdf`} target="_blank" rel="noreferrer" className="main-btn">
              <span className="btn-text">Resume</span>
              <FontAwesomeIcon className='btn-icon' icon={faDownload}/>
          </a>
        </div>
        <ul>
        <li>
          <a href="https://www.linkedin.com/in/paul-mooh-mooh-1ba8512a2/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" className={`anchor-icon ${activeBackground ? 'dark-mode' : ''}`}/>
          </a>
        </li>
        <li>
          <a href="https://github.com/Pcmooh23" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faGithub} color="#4d4d4e" className={`anchor-icon ${activeBackground ? 'dark-mode' : ''}`}/>
          </a>
        </li>
      </ul>
      </div>
    </div>
  </div>
  </WavyBackground>
    </>
 );
}

export default Home