import React, {useContext} from "react";
import { FaLink, FaGithub, } from "react-icons/fa";
import { FaRegRectangleXmark } from "react-icons/fa6";

import { StudioContext } from "../components/StudioContext";

export const ProjectDetails = () => {
  const {activeProject, active, closeProject, activeBackground} = useContext(StudioContext);

    return (
      <div className={`project-container ${active ? '' : 'hidden' } ${activeBackground ? 'dark-mode' : ''}`}>
          <div className={`project-info ${activeProject.title}`}>
            <div className={`project-details ${activeProject.title}`}>
              <FaRegRectangleXmark className="x-mark" onClick={closeProject}/>
              <div className="project-title">{activeProject.title}</div>
              <div className="project-desc">Description: {activeProject.description}</div>
              {activeProject.demoDetails ? <div className="demo-details">Demo Details: {activeProject.demoDetails}</div> : ''}
              <div className="project-stack">Tech Stack: {activeProject.techstack}</div>
              <div className={`project-anchors ${activeProject.title}`}>
               <a href={activeProject.demoLink}
                  target="_blank" rel="noopener noreferrer"
                  className={`${activeProject.title}-demo-anchor`}>
                    Live Demo <FaLink className="demo-icon"/>
                </a>
               <a href={activeProject.repoLink}
                  target="_blank" rel="noopener noreferrer"
                  className={`${activeProject.title}-repo-anchor`}>
                    Git Repo <FaGithub className="repo-icon"/>
                </a>
              </div>
            </div>
            <div> 
              <img alt={activeProject.title} className={`detail-image ${activeProject.title}`} src={activeProject.thumbnail}/>
            </div>
          </div>
      </div>
    );
  };