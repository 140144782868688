import React, {useEffect, useState, useContext, useRef } from "react";
import { motion, useScroll, useTransform, useSpring, MotionValue } from "framer-motion";
import AnimatedLetters from "../components/AnimatedLetters";
import { StudioContext } from "../components/StudioContext";
import { ProjectDetails } from "../components/ProjectDetails";

export const HeroParallax = ({ projects }: {
  projects: {
    title: string;
    thumbnail: string;
    titleCover: string;
  }[];
}) => {
  const {active, activeBackground} = useContext(StudioContext);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const dynamicTransformValue = screenSize > 768 ? 250 : (screenSize > 550 ? 100 : 80);
  const firstRow = projects;
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const springConfig = { stiffness: 300, damping: 30, bounce: 100 };

  const translateX = useSpring(
    useTransform(scrollYProgress, [0, 1], [0, dynamicTransformValue]),
    springConfig
  );
  const rotateX = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [15, 0]),
    springConfig
  );
  const opacity = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [0.2, 1]),
    springConfig
  );
  const rotateZ = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [20, 0]),
    springConfig
  );
  const translateY = useSpring(
    useTransform(scrollYProgress, [0, 0.2], [-450, 50]),
    springConfig
  );
  
  const smoothScrollTo = (target, duration) => {
    const startPosition = window.pageYOffset;
    const distance = target - startPosition;
    let startTime = 0;
  
    const animation = currentTime => {
      if (startTime === 0) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    };
  
    const easeInOutQuad = (time, start, distance, duration) => {
      time /= duration / 2;
      if (time < 1) return (distance / 2) * time * time + start;
      time--;
      return (-distance / 2) * (time * (time - 2) - 1) + start;
    };
  
    requestAnimationFrame(animation);
  };
  
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    if (active && window.scrollY < 100) {
      smoothScrollTo(450, 1000); 
    }
    return () => window.removeEventListener('resize', handleResize);
  }, [active])

  return (
    <div ref={ref} className={`parallax-container ${activeBackground ? 'dark-mode': ''}`}>
      <Header />
      <motion.div style={{ rotateX, rotateZ, translateY, opacity}}>
        <motion.div className={`flex-row-reverse-container ${active ? 'hidden' : ''} `}>
          {firstRow.map((project) => (
            <ProductCard project={project} translate={translateX} key={project.title}/>
          ))}
        </motion.div>
        <ProjectDetails/>
      </motion.div>
    </div>
  );
};


export const Header = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
  
    return () => clearTimeout(timer);
  }, []);
  const {activeBackground} = useContext(StudioContext);

  return (
    <div className="content-container">
      <h1 className={`title ${activeBackground ? 'dark-mode' : ''}`}>
          <AnimatedLetters letterClass={letterClass}
            strArray={['M', 'y', ' ', 'P', 'o', 'r', 't', 'f', 'o', 'l', 'i', 'o']}
            idx={15}/>
      </h1>
      <p className={`description ${activeBackground ? 'dark-mode' : ''}`}>
        With every new project, I strive to refine my skills,
        aiming always to elevate the quality and impact of my work, regardless of its scale.
      </p>
    </div>
  );
};

export const ProductCard = ({ project, translate }: {
  project: {
    title: string;
    thumbnail: string;
    titleCover: string;
  };
  translate: MotionValue<number>;
  }) => {

  const {showProjectDetails} = useContext(StudioContext);
  return (
    <motion.div
      style={{ x: translate }}
      whileHover={{ y: -20 }}
      key={project.title}
      className={`project-card ${project.title}`}>
        <img
          src={project.thumbnail}
          className="image"
          alt={project.title}/>
      <div className="overlay" onClick={() => showProjectDetails(project.title)}></div>
      <h2 className="title-text">
        {project.titleCover}
      </h2>
    </motion.div>
  );
};
