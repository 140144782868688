import React, { useState, useEffect, useContext } from 'react';
import { StudioContext } from './StudioContext.jsx';

const ScrollAnimation = () => {
  const [animate, setAnimate] = useState(false);
  const {activeBackground} = useContext(StudioContext);

  useEffect(() => {
    const timer = setInterval(() => {
      setAnimate(true); 

      setTimeout(() => setAnimate(false), 1600);
    }, 8000); 

    return () => clearInterval(timer); 
  }, []);

  return (
    <span className={`scroll-span ${activeBackground ? 'dark-mode' : ''}`}> 
      <span className={`scroll-letter ${animate ? 'animate' : ''}`} style={{ '--i': 0 }}>S</span>
      <span className={`scroll-letter ${animate ? 'animate' : ''}`} style={{ '--i': 1 }}>C</span>
      <span className={`scroll-letter ${animate ? 'animate' : ''}`} style={{ '--i': 2 }}>R</span>
      <span className={`scroll-letter ${animate ? 'animate' : ''}`} style={{ '--i': 3 }}>O</span>
      <span className={`scroll-letter ${animate ? 'animate' : ''}`} style={{ '--i': 4 }}>L</span>
      <span className={`scroll-letter ${animate ? 'animate' : ''}`} style={{ '--i': 5 }}>L</span>
    </span>
  );
};

export default ScrollAnimation;
