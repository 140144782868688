import React from 'react'
import { HeroParallax } from "../utils/hero-parallax.tsx";
import youwatchPic from '../assets/images/youtube_logos.jpeg';
import snowpallCover from '../assets/images/snowtech.png';

export function Portfolio() {
  return <HeroParallax projects={projects} />;
}
const projects = [
    {
      titleCover: "November 2023 - YouWatch",
      title: "YouWatch",
      demoLink: "https://pcmooh23.github.io/youwatch/",
      repoLink: "https://github.com/Pcmooh23/youwatch",
      thumbnail: youwatchPic,
      description: <div>YouWatch is a responsive, <b className='underline'>single-page application (SPA)</b> that emulates YouTube. 
      While creating a clone might not be original, replicating a familiar product has proven to be a 
      valuable exercise for developing and honing new skills. Despite its modest scale, the project
      presented numerous challenges during development. Having overcome these hurdles, I now feel more
      prepared to tackle more ambitious projects.</div>,
      techstack: <div>React JS, Sass, Docker</div>,
      demoDetails: null,
    },
    {
      titleCover: "March 2024 - SnowPall",
      title: "SnowPall",
      demoLink: "https://snowpall.com/",
      repoLink: "https://github.com/Pcmooh23/snowpall",
      thumbnail: snowpallCover,
      description: <div>SnowPall is a <b className='underline'>MERN stack</b> snow removal service <b className='underline'>SPA </b> 
      created with <b className='underline'>first & third party APIs</b> hosted via <b className='underline'>netlify</b> and the <b className='underline'>google cloud console</b>. It gives customers the power of 
      convience letting snowtechs handle their snow related jobs. </div>,
      techstack: <div>MongoDB, Express.js, React JS, Node.js, Sass, Docker</div>,
      demoDetails: <div>
                  For customer payment area use <b className='underline'>stripe</b> test card number 42 repeating.
                  <br/>Customer login & password: kim.possible@gmail.com, kim 
                  <br/>Snowtech login & password: ron.stoppable@gmail.com, ron</div>
    },
  ];